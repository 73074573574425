import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { FormsModule } from '@angular/forms';
import { CommonServiceLayerService } from '../services/common-service-layer.service';
import { SharedModule } from '../shared/shared.module';
import { MapLoaderModule } from '../shared/map-loader/map-loader.module';
import { UserLoginComponent } from './user-login/user-login.component';
import { ManualComplaintsComponent } from './manual-complaints/manual-complaints.component';
import { TrackComplaintsComponent } from './track-complaints/track-complaints.component';
import { TableModule } from '../shared/table/table.module';

@NgModule({
  declarations: [LoginComponent, UserLoginComponent, ManualComplaintsComponent, TrackComplaintsComponent],
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    MapLoaderModule,
    TableModule
  ],
  exports: [LoginComponent],
  providers: [CommonServiceLayerService]
})
export class LoginModule { }
