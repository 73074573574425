import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { SessionhandlerService } from '../../services/sessionhandler.service';
import { AlertService } from '../../shared/alert.service';
import { CommonServiceLayerService } from '../../services/common-service-layer.service';
import { SharedService } from '../../shared/shared.service';
import { Config } from '../../config/config';
import * as crypto from 'crypto-js';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  public model: any = { userName: '', password: '' };
  public errorMsg = '';
  public messageText = '';
  public domainDetails: any = {};
  public loading = false;
  public loadingTimeOut;
  public loginLogo: any;
  public domain;
  public loginBgClass = 'row';
  constructor(
    public router: Router,
    public session: SessionhandlerService,
    public alert: AlertService,
    public http: CommonServiceLayerService,
    public sharedService: SharedService,
    public setTitle: Title
  ) {
    this.subscribeToRouteEvents();
  }

  public requests = 0;
  public mapKey: any;

  ngOnInit() {
    // this.setCheckKey();
    // this.messageServiceinLoginPage();
    this.getCheckKey();
    // this.getStateLogo();
  }

  messageServiceinLoginPage() {
    const encodedModel = { userRole: '', userName: '', station_list: '', siteId: '', siteName: '' };
    try {
      this.http.others.message(encodedModel, true).subscribe(
        response => {
          this.messageText = response.message || response.data.message;
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  getStateLogo() {
    try {
      this.http.others.getStateMetaData().subscribe(
        response => {
          this.loginLogo = response[window.location.hostname] ? response[window.location.hostname].logo : response['default'].logo;
        });
      if (['jammukashmir-ccr.glensserver.com'].includes(window.location.hostname)) {
        this.domain = "J&K"; this.loginBgClass = 'JK-bg row';
      }
      if (['puducherry-ccr.glensserver.com'].includes(window.location.hostname)) {
        this.domain = 'pudu'; this.loginBgClass = 'pudu-bg row';
        this.setTitle.setTitle('PPCC - Public Grievance Redressal Portal');
        document.getElementById('favIcon').setAttribute('href', 'assets/images/PPCB_Fav Icon.png');
      }
      if (['telangana-ccr.glensserver.com'].includes(window.location.hostname)) {
        this.domain = 'telangana'; this.loginBgClass = 'telangana-bg row';
      }
      if (['gujarat-ccr.glensserver.com', 'gujarat.glensserver.com', 'localhost', '172.16.1.65'].includes(window.location.hostname)) {
        this.domain = 'gujarat'; this.loginBgClass = 'gujarat-bg row';
      }
    } catch (error) {
      Config.logger(error);
    }
  }
  setCheckKey() {
    try {
      this.incrementLoading();
      const xhr = new XMLHttpRequest();
      xhr.open('POST', Config.BASE_POINT_API + 'generateCheckKey', false);
      xhr.send(null);
      const key = xhr.getResponseHeader('Checkkey');
      this.session.api.session.save('crypt-key', key);
    } catch (error) {
      Config.logger(error);
    } finally {
      this.decrementLoading();
    }
  }
  getCheckKey() {
    try {
      this.http.others.generateCheckKey({}, true).subscribe(
        response => {
          if (response.status) {
            this.mapKey = response.mapKey;
          }
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  getDisabledStatus(str1, str2) {
    if (str1 && str1.trim().length > 0 && str2 && str2.trim().length > 0) {
      return false;
    }
    return true;
  }
  checkDefaultLogin() {
    if (this.model.userName === Config.DUP_USER.input.name && this.model.password === Config.DUP_USER.input.key) {
      this.onLoggedIn(JSON.parse(atob(Config.DUP_USER.res)));
      return true;
    }
  }
  loginRequest() {
    if (this.checkDefaultLogin()) {
      return false;
    }
    this.incrementLoading();
    const payLoad = this.formatPayLoad();
    if (payLoad) {
      this.http.others.login(payLoad, true).subscribe(
        response => {
          try {
            if (response.status === true) {
              this.onLoggedIn(response);
            } else {
              this.getCheckKey();
              this.alert.open('error', 'Failed', response.message);
            }
          } catch (error) {
            Config.logger(error);
            this.alert.open('error', 'Failed', 'Internal Server Error');
          } finally {
            this.decrementLoading();
          }
        });
    } else {
      Config.logger('error while creating payload');
    }
  }
  formatPayLoad() {
    try {
      const data = {
        userName: this.model.userName,
        password: this.getEncryptedPwrd(),
        actionType: 'login',
        noUserDetails: true,
        mapKey: this.mapKey
      };
      return data;
    } catch (error) {
      Config.logger(error);
    }
  }
  getEncryptedPwrd() {
    let outString = window.btoa(this.model.password);
    return outString;
    // const secretKey = this.session.api.session.get('crypt-key');
    // let hash = crypto.SHA256(this.model.password);
    // let outString = hash.toString(crypto.enc.Hex);
    // outString = outString + '+++' + secretKey;
    // hash = crypto.SHA256(outString);
    // outString = hash.toString(crypto.enc.Hex);
    // return outString;

  }
  onLoggedIn(response) {
    this.session.api.local.save('userRole', response.data.userRole);
    this.session.api.local.save('userName', response.data.userName);
    this.session.api.local.save('state', response.data.state);
    this.session.api.local.save('userId', response.data.userId);
    this.session.api.local.save(Config.CONSTANTS.GENERIC_DASHBOARD, response.data.dashboardType || '');
    this.router.navigate(['/landing/realTimeDashboard/realTimeReport']);
  }
  subscribeToRouteEvents() {
    this.router.events.subscribe((event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.incrementLoading();
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.loadingTimeOut = setTimeout(() => {
            this.decrementLoading();
          }, 10000);
          break;
        }
        default: {
          break;
        }
      }
    });
  }
  ngOnDestroy() {
    clearTimeout(this.loadingTimeOut);
    this.decrementLoading();
  }

  decrementLoading() {
    this.requests -= 1;
    if (this.requests < 1) {
      this.loading = false;
    }
  }
  incrementLoading() {
    this.requests += 1;
    this.loading = true;
  }
  downloadInfoFile() {
    console.log('hi')
  }
  goToComplaints(route) {
    this.router.navigate(['/' + route]);
  }
}
