import { environment } from '../../environments/environment';
export class Config {
  public static get BASE_POINT_API() { return environment.API_END_POINT; }
  public static get COMPLAINT_BASE_API() { return environment.COMPLAINT_API_END_POINT; }
  public static get AQI_BASE_POINT_API() { return environment.AQI_API_END_POINT; }
  public static get AERMOD_BASE_POINT_API() { return environment.AERMOD_API_END_POINT; }
  public static get MQTT_CONNECTION() { return environment.MQTT_CONNECTION; }
  public static get BASE_POINT_JSON() { return 'assets/jsons/'; }
  public static get IMAGE_BASE_POINT_JSON() { return './complaints/Images/'; }
  public static GLENS_IMAGE = true;
  public static FUJERAH_IMAGE = true;

  public static BASE_API_MAPPING = {
    common: Config.BASE_POINT_API + '',
    aqiCommon: Config.AQI_BASE_POINT_API + '',
    complaint: Config.COMPLAINT_BASE_API + '',
    aermod: Config.AERMOD_BASE_POINT_API + 'api/',
    aermodDownload: Config.AERMOD_BASE_POINT_API + 'static/'
  };
  // Json Base Points
  public static JSON = {
    // login
    login: Config.BASE_POINT_JSON + 'login.json',
    navHeader: Config.BASE_POINT_JSON + 'navHeader.json',
    message: Config.BASE_POINT_JSON + 'message.json',
    navHeaderDescription: Config.BASE_POINT_JSON + 'navHeaderDescription.json',
    navHeaderInfoIcon: Config.BASE_POINT_JSON + 'descriptionIcon.json',
    stateMetaData: Config.BASE_POINT_JSON + 'stateMetaData.json',

    // reports
    ParameterReportLoader: Config.BASE_POINT_JSON + 'ParameterReportLoader.json',
    iedReportCreate: Config.BASE_POINT_JSON + 'iedReportCreate.json',
    iedreportloader: Config.BASE_POINT_JSON + 'iedreportloader.json',
    averageReportLoader: Config.BASE_POINT_JSON + 'averageReportLoader.json',
    alarmReportLoader: Config.BASE_POINT_JSON + 'alarmReportLoader.json',
    requestMatrixdata: Config.BASE_POINT_JSON + 'requestMatrixdata.json',
    requestShiftReports: Config.BASE_POINT_JSON + 'requestShiftReports.json',
    requestAverageReport: Config.BASE_POINT_JSON + 'requestAverageReport.json',
    requestMultipleAverageReport: Config.BASE_POINT_JSON + 'requestMultipleAverageReport.json',
    requestDailyReport: Config.BASE_POINT_JSON + 'requestDailyReport.json',
    requestMonthlyAverage: Config.BASE_POINT_JSON + 'requestMonthlyAverage.json',
    requestDynamicReport: Config.BASE_POINT_JSON + 'requestDynamicReport.json',
    dynamicLiveData: Config.BASE_POINT_JSON + 'dynamicLiveData.json',
    requestTotalizerReport: Config.BASE_POINT_JSON + 'requestTotalizerReport.json',
    hourlyExceedance: Config.BASE_POINT_JSON + 'hourlyExceedance.json',
    exceedanceReportLoader: Config.BASE_POINT_JSON + 'exceedanceReportLoader.json',
    requestExceedenceFilterLoader: Config.BASE_POINT_JSON + 'exceedenceReportFilters.json',
    calculateAverageExceedance: Config.BASE_POINT_JSON + 'calculateAverageExceedance.json',
    batchReportLoader: Config.BASE_POINT_JSON + 'batchReportLoader.json',
    editBatchReportDetails: Config.BASE_POINT_JSON + 'editBatchReportDetails.json',

    fetchAverageData: Config.BASE_POINT_JSON + 'averageSummary.json',
    metrologicalReportLoader: Config.BASE_POINT_JSON + 'meterologicReport.json',
    getMetroData: Config.BASE_POINT_JSON + 'meterologicReportTable.json',
    requestParameterSummary: Config.BASE_POINT_JSON + 'parameterSummary.json',
    requestRealTimeReport: Config.BASE_POINT_JSON + 'requestRealTimeReport.json',
    requestSiteInfo: Config.BASE_POINT_JSON + 'siteInfo.json',
    // others
    fetchAuditAlarm: Config.BASE_POINT_JSON + 'fetchAuditAlarm.json',
    todayAlarm: Config.BASE_POINT_JSON + 'todayAlarm.json',
    requestHeadersExceedanceReport: Config.BASE_POINT_JSON + 'requestHeadersExceedanceReport.json',
    requestHeadersExceedanceCountLoader: Config.BASE_POINT_JSON + 'requestHeadersExceedanceCountLoader.json',
    requestHeadersMatrixReport: Config.BASE_POINT_JSON + 'requestHeadersMatrixReport.json',
    requestHeadersShiftReport: Config.BASE_POINT_JSON + 'requestHeadersShiftReport.json',
    requestHeadersStationwiseReport: Config.BASE_POINT_JSON + 'requestHeadersStationwiseReport.json',
    requestHeadersDailySummaryReport: Config.BASE_POINT_JSON + 'requestHeadersDailySummaryReport.json',
    requestHeadersMonthlySummaryReport: Config.BASE_POINT_JSON + 'requestHeadersMonthlySummaryReport.json',
    scatterPlotReportLoader: Config.BASE_POINT_JSON + 'scatterPlotReportLoader.json',
    windroseReportLoader: Config.BASE_POINT_JSON + 'windroseReportLoader.json',
    monthlySummaryGraphLoader: Config.BASE_POINT_JSON + 'monthlySummaryGraphLoader.json',
    requestHeadersRawReport: Config.BASE_POINT_JSON + 'requestHeadersRawReport.json',
    rawGraphLoader: Config.BASE_POINT_JSON + 'rawGraphLoader.json',
    requestHeadersDynamicLiveData: Config.BASE_POINT_JSON + 'requestHeadersDynamicLiveData.json',
    requestHeadersTotalizerReport: Config.BASE_POINT_JSON + 'requestHeadersTotalizerReport.json',
    requestHeadersIEDComplainceReport: Config.BASE_POINT_JSON + 'requestHeadersIEDComplainceReport.json',
    iedComplainceGraphLoader: Config.BASE_POINT_JSON + 'iedComplainceGraphLoader.json',
    requestHeadersDynamicLiveReport: Config.BASE_POINT_JSON + 'requestHeadersDynamicLiveReport.json',
    scheduledReportLoader: Config.BASE_POINT_JSON + 'scheduledReportLoader.json',
    scheduledGraphLoader: Config.BASE_POINT_JSON + 'scheduledGraphLoader.json',
    parameterSelectionReportLoader: Config.BASE_POINT_JSON + 'parameterSelectionReportLoader.json',
    histogramReportLoader: Config.BASE_POINT_JSON + 'histogramReportLoader.json',
    requestHeadersWindrose: Config.BASE_POINT_JSON + 'requestHeadersWindrose.json',
    getTableDataBasedOnSite: Config.BASE_POINT_JSON + 'getTableDataBasedOnSite.json',
    requestPostDataValidation: Config.BASE_POINT_JSON + 'requestPostDataValidation.json',
    normalizationReportLoader: Config.BASE_POINT_JSON + 'normalizationReportLoader.json',
    staticFilters: Config.BASE_POINT_JSON + 'staticFilters.json',

    // Analytic Reports
    requestHistogramReport: Config.BASE_POINT_JSON + 'requestHistogramReport.json',
    getWindRose: Config.BASE_POINT_JSON + 'getWindRose.json',
    requestPercentileReport: Config.BASE_POINT_JSON + 'requestPercentileReport.json',
    requestUptimeReport: Config.BASE_POINT_JSON + 'requestUptimeReport.json',
    requestScatterReport: Config.BASE_POINT_JSON + 'requestScatterReport.json',
    normalizationVerifyData: Config.BASE_POINT_JSON + 'normalizationVerifyData.json',
    saveAnalytics: Config.BASE_POINT_JSON + 'saveAnalytics.json',
    analyticsReportLoad: Config.BASE_POINT_JSON + 'analytics_report_load.json',
    getPolarisedWindrose: Config.BASE_POINT_JSON + 'getPolarisedWindrose.json',
    requestAllAnalytics: Config.BASE_POINT_JSON + 'requestAllAnalytics.json',

    // realtime
    newDashboardView: Config.BASE_POINT_JSON + 'newDashboardView.json',
    siteList: Config.BASE_POINT_JSON + 'siteList.json',
    sitedetails: Config.BASE_POINT_JSON + 'sitedetails.json',
    stationData: Config.BASE_POINT_JSON + 'stationData.json',
    geochartmapLoader: Config.BASE_POINT_JSON + 'geochartmapLoader.json',
    geochartmapLoaderDM: Config.BASE_POINT_JSON + 'geochartmapLoaderDM.json',
    geomapLoaderDashboardDM: Config.BASE_POINT_JSON + 'geochartmapDashboardDM.json',
    getStationInfo: Config.BASE_POINT_JSON + 'getStationInfo.json',
    getSitesList: Config.BASE_POINT_JSON + 'getSitesList.json',
    getFujairahGeoJson: Config.BASE_POINT_JSON + 'Fujairah_L.json',
    siteParameterStatus: Config.BASE_POINT_JSON + 'siteParameterStatus.json',
    allMetReports: Config.BASE_POINT_JSON + 'allMetReport.json',
    fetchAverageMetaData: Config.BASE_POINT_JSON + 'fetchAverageMetaData.json',
    dataAvailabilityReport: Config.BASE_POINT_JSON + 'dataAvailabilityReport.json',
    fetchLiveData: Config.BASE_POINT_JSON + 'fetchLiveData.json',
    fetchDashboardDataDM: Config.BASE_POINT_JSON + 'liveDashboardDM.json',
    siteStatus: Config.BASE_POINT_JSON + 'siteStatus.json',
    publicAqiMap: Config.BASE_POINT_JSON + 'publicAqiMap.json',
    fetchStationParamData: Config.BASE_POINT_JSON + 'fetchStationParamData.json',
    GET_NOISE_STATION_LIST: Config.BASE_POINT_JSON + 'fetchNoiseStationList.json',
    GET_NOISE_TABLE_DATA: Config.BASE_POINT_JSON + 'fetchNoiseTable.json',

    // Maintenance
    maintenanceLoader: Config.BASE_POINT_JSON + 'maintenanceLoader.json',
    requestMaintenanceLoader: Config.BASE_POINT_JSON + 'requestMaintenanceLoader.json',
    maintenanceReport: Config.BASE_POINT_JSON + 'maintenanceReport.json',
    loadAddOperHours: Config.BASE_POINT_JSON + 'loadAddOperHours.json',
    operationSetup: Config.BASE_POINT_JSON + 'operationSetup.json',

    // diagnostic
    diagnosticsReport: Config.BASE_POINT_JSON + 'diagnosticsReport.json',
    requestdiagnosticsLoader: Config.BASE_POINT_JSON + 'requestdiagnosticsLoader.json',
    analyserStatus: Config.BASE_POINT_JSON + 'analyserStatus.json',
    diagnosticParameterReport: Config.BASE_POINT_JSON + 'diagnosticParameterReport.json',
    requestdiagnosticsParamLoader: Config.BASE_POINT_JSON + 'requestdiagnosticsParamLoader.json',

    // Calibration
    calibrationLoader: Config.BASE_POINT_JSON + 'calibrationLoader.json',
    requestCalibrationLoader: Config.BASE_POINT_JSON + 'requestCalibrationLoader.json',
    fetchTrigger: Config.BASE_POINT_JSON + 'fetchTrigger.json',
    qualitycontrolcharts: Config.BASE_POINT_JSON + 'qualitycontrolcharts.json',
    requestCusumReport: Config.BASE_POINT_JSON + 'requestCusumReport.json',
    requestEwmaReport: Config.BASE_POINT_JSON + 'requestEwmaReport.json',
    requestShewhartReport: Config.BASE_POINT_JSON + 'requestShewhartReport.json',
    callibrationReprot: Config.BASE_POINT_JSON + 'callibrationReprot.json',
    remoteCalibrationStatus: Config.BASE_POINT_JSON + 'remoteCalibrationStatus.json',
    calendarCalibration: Config.BASE_POINT_JSON + 'calendarCalibration.json',
    loadAddQual2: Config.BASE_POINT_JSON + 'qual2Add.json',
    q2Table: Config.BASE_POINT_JSON + 'q2Table.json',
    q2astTable: Config.BASE_POINT_JSON + 'qual2Table.json',
    qualitycontrolChartsLoader: Config.BASE_POINT_JSON + 'qualitycontrolChartsLoader.json',


    // audit logs
    requestauditLog: Config.BASE_POINT_JSON + 'requestauditLog.json',
    glensVersions: Config.BASE_POINT_JSON + 'glensVersions.json',

    // comments
    FetcheComment: Config.BASE_POINT_JSON + 'FetcheComment.json',
    PostDataValidation: Config.BASE_POINT_JSON + 'PostDataValidation.json',

    // site setup
    loadSiteDetails: Config.BASE_POINT_JSON + 'loadSiteDetails.json',
    advancedSetupLoader: Config.BASE_POINT_JSON + 'advancedSetupLoader.json',
    advancedParameterReportLoader: Config.BASE_POINT_JSON + 'advancedParameterReportLoader.json',
    loadNormalization: Config.BASE_POINT_JSON + 'loadNormalization.json',
    loadNormalizationFilter: Config.BASE_POINT_JSON + 'loadNormalizationFilter.json',
    loadFilter: Config.BASE_POINT_JSON + 'loadFilter.json',
    loadSiteConfigFilter: Config.BASE_POINT_JSON + 'loadSiteConfigFilter.json',
    siteConfigLoad: Config.BASE_POINT_JSON + 'siteConfigLoad.json',
    loadAddUserFilter: Config.BASE_POINT_JSON + 'loadAddUserFilter.json',
    loadUser: Config.BASE_POINT_JSON + 'loadUser.json',
    loadPasswordChangeModal: Config.BASE_POINT_JSON + 'loadPasswordChangeModal.json',
    fuelSetup: Config.BASE_POINT_JSON + 'fuelSetup.json',
    loadAddFuelFilter: Config.BASE_POINT_JSON + 'loadAddFuelFilter.json',
    networkDetails: Config.BASE_POINT_JSON + 'networkDetails.json',
    loadAddNetwork: Config.BASE_POINT_JSON + 'loadAddNetwork.json',
    fetchShift: Config.BASE_POINT_JSON + 'fetchShift.json',
    loadAddGeneralFilter: Config.BASE_POINT_JSON + 'loadAddGeneralFilter.json',
    loadStationDetails: Config.BASE_POINT_JSON + 'loadStationDetails.json',
    loadMonitoringStationFilter: Config.BASE_POINT_JSON + 'loadMonitoringStationFilter.json',
    loadAddMonitoringStation: Config.BASE_POINT_JSON + 'loadAddMonitoringStation.json',
    loadCollector: Config.BASE_POINT_JSON + 'loadCollector.json',
    loadAddCollector: Config.BASE_POINT_JSON + 'loadAddCollector.json',
    loadAddValueCollector: Config.BASE_POINT_JSON + 'loadAddValueCollector.json',
    cylinderSetupLoader: Config.BASE_POINT_JSON + 'cylinderSetupLoader.json',
    loadAddNewCylinder: Config.BASE_POINT_JSON + 'loadAddNewCylinder.json',
    smsLoader: Config.BASE_POINT_JSON + 'smsLoader.json',
    loadAddSms: Config.BASE_POINT_JSON + 'loadAddSms.json',
    loadFeatures: Config.BASE_POINT_JSON + 'loadFeatures.json',
    loadAddUserRole: Config.BASE_POINT_JSON + 'loadAddUserRole.json',
    alertMailConfigLoader: Config.BASE_POINT_JSON + 'alertMailConfigLoader.json',
    displayLoad: Config.BASE_POINT_JSON + 'displayLoad.json',
    displayBoardWindow: Config.BASE_POINT_JSON + 'displayBoardWindow.json',
    loadDisplaySave: Config.BASE_POINT_JSON + 'loadDisplaySave.json',
    parameterSetupLoader: Config.BASE_POINT_JSON + 'parameterSetupLoader.json',
    loadParameterFilter: Config.BASE_POINT_JSON + 'loadParameterFilter.json',
    loadAddParameter: Config.BASE_POINT_JSON + 'loadAddParameter.json',
    loadActiveTable: Config.BASE_POINT_JSON + 'loadActiveTable.json',
    loadActiveSearchTable: Config.BASE_POINT_JSON + 'activeDirectorySearch.json',
    saveMonitoringData: Config.BASE_POINT_JSON + 'saveMonitoringData.json',
    loadiedpm1: Config.BASE_POINT_JSON + 'iedpm1Load.json',
    fetchiedpm1: Config.BASE_POINT_JSON + 'iedpm1Fetch.json',
    loadDynamicLimits: Config.BASE_POINT_JSON + 'loadDynamicLimits.json',
    fetchiedaqra: Config.BASE_POINT_JSON + 'iedaqraFetch.json',
    loadGeneralSettingsLoader: Config.BASE_POINT_JSON + 'loadGeneralSettingsLoader.json',
    generalSetuptLoader: Config.BASE_POINT_JSON + 'generalSetuptLoader.json',
    loadAermodMonitoringStation: Config.BASE_POINT_JSON + 'loadAermodMonitoringStation.json',
    dubaiDMGeoJson: Config.BASE_POINT_JSON + 'dubaiDMGeoJson.json',
    loadFlagsetup: Config.BASE_POINT_JSON + 'loadFlagsetup.json',
    loadFlagSetupForm: Config.BASE_POINT_JSON + 'loadFlagSetupForm.json',
    loadQualifierCodeSetupForm: Config.BASE_POINT_JSON + 'loadQualifierCodeSetupForm.json',
    loadQualifierCodeSetup: Config.BASE_POINT_JSON + 'loadQualifierCodeSetup.json',
    loadCommentssetup: Config.BASE_POINT_JSON + 'loadCommentssetup.json',
    loadCommentSetupForm: Config.BASE_POINT_JSON + 'loadCommentSetupForm.json',
    loadCategorySetup: Config.BASE_POINT_JSON + 'loadCategorySetup.json',
    loadCategorySetupForm: Config.BASE_POINT_JSON + 'loadCategorySetupForm.json',
    stateGeojson: Config.BASE_POINT_JSON + 'stateGeoJson.json',

    // common success
    commonSuccessJson: Config.BASE_POINT_JSON + 'commonSuccessJson.json',

    // printer
    CsvDump: Config.BASE_POINT_JSON + 'CsvDump.json',
    PdfDump: Config.BASE_POINT_JSON + 'PdfDump.json',
    excelDump: Config.BASE_POINT_JSON + 'excelDump.json',

    // AQI Reports
    AQIDataReport: Config.BASE_POINT_JSON + 'AQIDataReport.json',
    AQILevelReport: Config.BASE_POINT_JSON + 'AQILevelReport.json',
    AQIHourlyCalenderView: Config.BASE_POINT_JSON + 'AQIHourlyCalenderView.json',
    AQIMonthlyCalenderView: Config.BASE_POINT_JSON + 'AQIMonthlyCalenderView.json',


    // complaints
    complaintsManagementFilter: Config.BASE_POINT_JSON + 'complaintsManagementFilter.json',
    complaintManagementTableLoader: Config.BASE_POINT_JSON + 'complaintManagementTableLoader.json',
    complaintManagementTabLoader: Config.BASE_POINT_JSON + 'complaintManagementTab.json',
    viewComplaintLoader: Config.BASE_POINT_JSON + 'complaintView.json',
    deleteComplaintLoader: Config.BASE_POINT_JSON + 'deleteComplaintLoader.json',
    complaintEntryFields: Config.BASE_POINT_JSON + 'complaintEntryFields.json',
    complaintEntryUploadFields: Config.BASE_POINT_JSON + 'complaintLoaderForm.json',
    complaintTrackerData: Config.BASE_POINT_JSON + 'trackComplaint.json',
    complaintEntryDropDownData: Config.BASE_POINT_JSON + 'complaint_loader.json',
    complaintEnteredFields: Config.BASE_POINT_JSON + 'complaintEnteredFields.json',
    inspectionMultiHeaderTable: Config.BASE_POINT_JSON + 'complaintsMultiheaderTable.json',

    // aermod
    get_all_projects: Config.BASE_POINT_JSON + 'get_all_projects.json',
    get_project_generated: Config.BASE_POINT_JSON + 'get_project_generated.json',
    fetch_all_sites: Config.BASE_POINT_JSON + 'fetch_all_sites.json',
    fetch_site_metadata: Config.BASE_POINT_JSON + 'fetch_site_metadata.json',
    fetch_list_of_buildings: Config.BASE_POINT_JSON + 'fetch_list_of_buildings.json',
    get_site_terrain_details: Config.BASE_POINT_JSON + 'get_site_terrain_details.json',

    // data validation
    dataValidationTable: Config.BASE_POINT_JSON + 'dataValidationTable.json',


    // AqiDataEntry
    fetchAqiDataEntryList: Config.BASE_POINT_JSON + 'fetchAqiDataEntryList.json',
    fetchAqiDataEntryForm: Config.BASE_POINT_JSON + 'fetchAqiDataEntryForm.json',
    aqiEntryParamsMetaData: Config.BASE_POINT_JSON  + 'aqiEntryParamsMetaData.json',
    aaqmsStationForm: Config.BASE_POINT_JSON  + 'aaqmsStationForm.json',
    AQIFormJson: Config.BASE_POINT_JSON  + 'aqiformJson.json',
    allStationData: Config.BASE_POINT_JSON  + 'allStationData.json',
    getComparisonData: Config.BASE_POINT_JSON  + 'getComparisonData.json',
    getAAQMSStationList: Config.BASE_POINT_JSON  + 'getAAQMSStationList.json',
  };

  // API END POINTS
  public static SERVICE_IDENTIFIER = {
    // others
    dataValidationStatic: 'assets/jsons/dataValidationStatic.json',

    // generateCheckKey: 'http://192.168.1.31:9867/kl/ui/generateCheckKey',
    login: Config.BASE_API_MAPPING.common + 'login',
    logout: Config.BASE_API_MAPPING.common + 'logout',
    generateCheckKey: Config.BASE_API_MAPPING.common + 'generateCheckKey',
    // login: 'http://192.168.1.31:9867/kl/ui/login',
    message: Config.BASE_API_MAPPING.common + 'message',
    // navHeader: Config.BASE_API_MAPPING.common + 'navHeader',
    getTableDataBasedOnSite: Config.BASE_API_MAPPING.common + 'averageReportLoader',
    fetchAuditAlarm: Config.BASE_API_MAPPING.common + 'fetchAuditAlarm',
    todayAlarm: Config.BASE_API_MAPPING.common + 'todayAlarm',
    siteDashboardLoader: Config.BASE_API_MAPPING.common + 'siteDashboardLoader',
    siteDashboardTabLoader: Config.BASE_API_MAPPING.common + 'navHeader',
    // staticFilters: 'http://192.168.1.31:9867/kl/ui/staticFilters',
    // stateGeojson: 'http://192.168.1.31:9867/kl/ui/stateGeojson',
    staticFilters: Config.BASE_API_MAPPING.common + 'staticFilters',
    stateGeojson: Config.BASE_API_MAPPING.common + 'geoJson',

    // reports
    iedReportCreate: Config.BASE_API_MAPPING.common + 'iedReportCreate',
    iedreportloader: Config.BASE_API_MAPPING.common + 'iedreportloader',
    averageReportLoader: Config.BASE_API_MAPPING.common + 'averageReportLoader',
    alarmReportLoader: Config.BASE_API_MAPPING.common + 'alarmReportLoader',
    requestMatrixdata: Config.BASE_API_MAPPING.common + 'requestMatrixdata',
    requestShiftReports: Config.BASE_API_MAPPING.common + 'requestShiftReports',
    requestAverageReport: Config.BASE_API_MAPPING.common + 'requestAverageReport',
    // requestAQIParameterReport: 'http://192.168.1.31:9867/kl/ui/parameterTrend',
    // AQITrendsReport: 'http://192.168.1.31:9867/kl/ui/aqiTrend',
    requestAQIParameterReport: Config.BASE_API_MAPPING.common + 'parameterTrend',
    requestNoiseTrendsData: Config.BASE_API_MAPPING.common + 'noiseTrend',
    AQITrendsReport: Config.BASE_API_MAPPING.common + 'aqiTrend',
    AQIFormJson: Config.BASE_API_MAPPING.common + 'formJson',
    requestStationList: Config.BASE_API_MAPPING.common + 'onloadListAll',
    getComparisonData: Config.BASE_API_MAPPING.common + 'comparisionData',
    getComparisonExcelData: Config.BASE_API_MAPPING.common + 'comp_excel',
    
    // requestAverageReport: '/sanjay_services/' + 'requestAverageReport',

    requestSiteInfo: Config.BASE_API_MAPPING.common + 'requestSiteInfo',
    requestRealTimeReport: Config.BASE_API_MAPPING.common + 'requestRealTimeReport',
    requestMultipleAverageReport: Config.BASE_API_MAPPING.common + 'requestMultipleAverageReport',
    requestDailyReport: Config.BASE_API_MAPPING.common + 'requestDailyReport',
    requestMonthlyAverage: Config.BASE_API_MAPPING.common + 'requestMonthlyAverage',
    requestDynamicReport: Config.BASE_API_MAPPING.common + 'requestDynamicReport',
    dynamicLiveData: Config.BASE_API_MAPPING.common + 'dynamicLiveData',
    requestTotalizerReport: Config.BASE_API_MAPPING.common + 'requestTotalizerReport',
    dynamicLiveReportGraphLoader: Config.BASE_API_MAPPING.common + 'dynamicLiveReportGraphLoader',
    requestFortnightReports: Config.BASE_API_MAPPING.common + 'requestFortnightReports',
    iedPdfHandler: Config.BASE_API_MAPPING.common + 'iedPdfHandler',
    hourlyExceedance: Config.BASE_API_MAPPING.common + 'hourlyExceedance',
    exceedanceReportLoader: Config.BASE_API_MAPPING.common + 'exceedanceReportLoader',
    dataAvailabilityReport: Config.BASE_API_MAPPING.common + 'dataAvailabilityReport',
    calculateAverageExceedance: Config.BASE_API_MAPPING.common + 'calculateAverageExceedance',
    getMetroData: Config.BASE_API_MAPPING.common + 'getMetrologicalReports',
    batchReportLoader: Config.BASE_API_MAPPING.common + 'scheduleReport', // 'batchReportLoader',
    addBatchReportDetails: Config.BASE_API_MAPPING.common + 'submitReport', // addBatchReportDetails',
    editBatchReportDetails: Config.BASE_API_MAPPING.common + 'editReport', // 'editBatchReportDetails',
    deleteBatchReport: Config.BASE_API_MAPPING.common + 'deleteReport', // 'deleteBatchReport',
    fetchScheduleDetails: Config.BASE_API_MAPPING.common + 'fetchScheduleDetails',
    getBatchCSVReport: Config.BASE_API_MAPPING.common + 'getBatchCSVReport',
    getBatchPDFReport: Config.BASE_API_MAPPING.common + 'getBatchPDFReport',
    requestParameterSummary: Config.BASE_API_MAPPING.common + 'requestParameterSummary',
    fetchAverageData: Config.BASE_API_MAPPING.common + 'fetchAverageData',
    requestDataAvailabilityDayWiseReport: Config.BASE_API_MAPPING.common + 'requestDataAvailabilityDayWiseReport',
    // realtime report
    GET_NOISE_STATION_LIST: Config.BASE_API_MAPPING.common + 'aqiMap',
    newDashboardView: Config.BASE_API_MAPPING.common + 'newDashboardView',
    siteList: Config.BASE_API_MAPPING.common + 'siteList',
    sitedetails: Config.BASE_API_MAPPING.common + 'sitedetails',
    stationData: Config.BASE_API_MAPPING.common + 'stationData',
    geochartmapLoader: Config.BASE_API_MAPPING.common + 'geoChartMapLoader',
    geochartmapLoaderDM: Config.BASE_API_MAPPING.common + 'geochartmapLoaderDM',
    getStationInfo: Config.BASE_API_MAPPING.common + 'siteDashboardDetails',
    getSitesList: Config.BASE_API_MAPPING.common + 'getSitesList',
    // publicAqiMap: Config.BASE_API_MAPPING.common + 'publicAqiMap',
    publicAqiMap: Config.BASE_API_MAPPING.common + 'aqiMap',
    // standard_metadata: Config.BASE_API_MAPPING.common + 'standard_metadata',
    standard_metadata: Config.BASE_API_MAPPING.common + 'aqiStandard',
    paramChart: Config.BASE_API_MAPPING.common + 'paramChart',
    siteParameterStatus: Config.BASE_API_MAPPING.common + 'siteParameterStatus',
    allMetReports: Config.BASE_API_MAPPING.common + 'allMetReports',
    analyticsReportLoad: Config.BASE_API_MAPPING.common + 'analyticsReportLoad',
    fetchLiveData: Config.BASE_API_MAPPING.common + 'fetchLiveData',
    fetchDashboardDataDM: Config.BASE_API_MAPPING.common + 'fetchDashboardDataDM',
    siteStatus: Config.BASE_API_MAPPING.common + 'stationStatus',
    fetchStationParamData: Config.BASE_API_MAPPING.common + 'fetchStationParamData',
    // Analytic Reports
    requestHistogramReport: Config.BASE_API_MAPPING.common + 'requestHistogramReport',
    getWindRose: Config.BASE_API_MAPPING.common + 'getWindRose',
    requestPercentileReport: Config.BASE_API_MAPPING.common + 'requestPercentileReport',
    requestUptimeReport: Config.BASE_API_MAPPING.common + 'requestUptimeReport',
    requestScatterReport: Config.BASE_API_MAPPING.common + 'requestScatterReport',
    requestAllAnalytics: Config.BASE_API_MAPPING.common + 'requestAllAnalytics',
    normalizationVerifyData: Config.BASE_API_MAPPING.common + 'normalizationVerifyData',
    saveAnalytics: Config.BASE_API_MAPPING.common + 'save_analytics',
    getPolarisedWindrose: Config.BASE_API_MAPPING.common + 'getPolarisedWindrose',
    // Maintenance
    maintenanceLoader: Config.BASE_API_MAPPING.common + 'maintenanceLoader',
    requestMaintenanceLoader: Config.BASE_API_MAPPING.common + 'requestMaintenanceLoader',
    saveMaintenance: Config.BASE_API_MAPPING.common + 'saveMaintenance',
    maintenanceReport: Config.BASE_API_MAPPING.common + 'maintenanceReport',
    operationSetup: Config.BASE_API_MAPPING.common + 'operationSetup',
    operationhrsInsert: Config.BASE_API_MAPPING.common + 'operationhrsInsert',
    updateOperatingHours: Config.BASE_API_MAPPING.common + 'updateOperatingHours',
    deleteOperationSetup: Config.BASE_API_MAPPING.common + 'deleteOperationSetup',

    // diagnostic
    diagnosticsReport: Config.BASE_API_MAPPING.common + 'diagnosticsReport',
    requestdiagnosticsLoader: Config.BASE_API_MAPPING.common + 'requestdiagnosticsLoader',
    analyserStatus: Config.BASE_API_MAPPING.common + 'analyserStatus',
    diagnosticParameterReport: Config.BASE_API_MAPPING.common + 'diagnosticParameterReport',
    requestdiagnosticsParamLoader: Config.BASE_API_MAPPING.common + 'requestdiagnosticsParamLoader',

    // calibration
    calibrationLoader: Config.BASE_API_MAPPING.common + 'calibrationLoader',
    requestCalibrationLoader: Config.BASE_API_MAPPING.common + 'requestCalibrationLoader',
    fetchTrigger: Config.BASE_API_MAPPING.common + 'fetchTrigger',
    qualitycontrolcharts: Config.BASE_API_MAPPING.common + 'qualitycontrolcharts',
    requestCusumReport: Config.BASE_API_MAPPING.common + 'requestCusumReport',
    requestEwmaReport: Config.BASE_API_MAPPING.common + 'requestEwmaReport',
    requestShewhartReport: Config.BASE_API_MAPPING.common + 'requestShewhartReport',
    callibrationReprot: Config.BASE_API_MAPPING.common + 'callibrationReprot',
    remoteCalibrationStatus: Config.BASE_API_MAPPING.common + 'remoteCalibrationStatus',
    calendarCalibration: Config.BASE_API_MAPPING.common + 'calendarCalibration',
    addSequence: Config.BASE_API_MAPPING.common + 'addSequence',
    approvecalibration: Config.BASE_API_MAPPING.common + 'approvecalibration',
    trigSequence: Config.BASE_API_MAPPING.common + 'trigSequence',

    // form Jsons
    formJsonLoader: Config.BASE_API_MAPPING.common + 'formJsonLoader',
    // formJsonLoader: '/sanjay_services/' + 'formJsonLoader',

    // audit logs
    requestauditLog: Config.BASE_API_MAPPING.common + 'requestauditLog',
    glensVersions: Config.BASE_API_MAPPING.common + 'glensVersions',

    // Comments
    fetcheComment: Config.BASE_API_MAPPING.common + 'fetcheComment',
    PostDataValidation: Config.BASE_API_MAPPING.common + 'PostDataValidation',

    // Data Validation
    fetchSites: Config.BASE_API_MAPPING.common + 'fetchSites',
    siteParameter: Config.BASE_API_MAPPING.common + 'siteParameter',
    dataValidationTable: Config.BASE_API_MAPPING.common + 'dataValidationTable',
    // dataValidationTable: '/sanjay_services/' + 'dataValidationTable',
    // submitDataValidation: '/sanjay_services/' + 'submitDataValidation',
    submitDataValidation: Config.BASE_API_MAPPING.common + 'submitDataValidation',
    allMetadataSubmit: Config.BASE_API_MAPPING.common + 'allMetadataSubmit',
    revertDataValidation: Config.BASE_API_MAPPING.common + 'revertDataValidation',
    fetchDataValidationConstants: Config.BASE_API_MAPPING.common + 'fetchDataValidationConstants',
    // fetchDataValidationConstants: '/sanjay_services/' + 'fetchDataValidationConstants',

    // site setup
    saveCatchmentArea: Config.BASE_API_MAPPING.common + 'saveCatchmentArea',
    siteSetupLoader: Config.BASE_API_MAPPING.common + 'siteSetupLoader',
    setupStationLoader: Config.BASE_API_MAPPING.common + 'setupStationLoader',
    addStation: Config.BASE_API_MAPPING.common + 'addStation',
    loadAddMonitoringStation: Config.BASE_API_MAPPING.common + 'loadAddMonitoringStation',
    siteSetupAdd: Config.BASE_API_MAPPING.common + 'siteSetupAdd',
    siteSetupUpdate: Config.BASE_API_MAPPING.common + 'siteSetupUpdate',
    siteSetupDelete: Config.BASE_API_MAPPING.common + 'siteSetupDelete',
    fuelSetup: Config.BASE_API_MAPPING.common + 'fuelSetup',
    fuelSetupInsert: Config.BASE_API_MAPPING.common + 'fuelSetupInsert',
    fuelEdit: Config.BASE_API_MAPPING.common + 'fuelEdit',
    fuelDelete: Config.BASE_API_MAPPING.common + 'fuelDelete',
    loadNormalization: Config.BASE_API_MAPPING.common + 'loadNormalization',
    updateNormalization: Config.BASE_API_MAPPING.common + 'updateNormalization',
    networkDetails: Config.BASE_API_MAPPING.common + 'networkDetails',
    updateNetwork: Config.BASE_API_MAPPING.common + 'updateNetwork',
    smsLoader: Config.BASE_API_MAPPING.common + 'smsLoader',
    loadAddSms: Config.BASE_API_MAPPING.common + 'loadAddSms',
    smsAdd: Config.BASE_API_MAPPING.common + 'smsAdd',
    smsEdit: Config.BASE_API_MAPPING.common + 'smsEdit',
    fetchShift: Config.BASE_API_MAPPING.common + 'fetchShift',
    loadUser: Config.BASE_API_MAPPING.common + 'loadUser',
    userSetup: Config.BASE_API_MAPPING.common + 'userSetup',
    addUser: Config.BASE_API_MAPPING.common + 'addUser',
    editUser: Config.BASE_API_MAPPING.common + 'editUser',
    editPassword: Config.BASE_API_MAPPING.common + 'editPassword',
    configureDashboardType: Config.BASE_API_MAPPING.common + 'configureDashboardType', // Config.BASE_API_MAPPING.common + ''
    deleteUser: Config.BASE_API_MAPPING.common + 'deleteUser',
    createShift: Config.BASE_API_MAPPING.common + 'createShift',
    deleteShift: Config.BASE_API_MAPPING.common + 'deleteShift',
    uploadLogo: Config.BASE_API_MAPPING.common + 'uploadLogo',
    cylinderSetupLoader: Config.BASE_API_MAPPING.common + 'cylinderSetupLoader',
    addCylinder: Config.BASE_API_MAPPING.common + 'addCylinder',
    deleteCylinder: Config.BASE_API_MAPPING.common + 'deleteCylinder',
    emailConfigSave: Config.BASE_API_MAPPING.common + 'emailConfigSave',
    emailConfigLoader: Config.BASE_API_MAPPING.common + 'emailConfigLoader',
    smsDelete: Config.BASE_API_MAPPING.common + 'smsDelete',
    loadCollector: Config.BASE_API_MAPPING.common + 'loadCollector',
    alertMailConfigLoader: Config.BASE_API_MAPPING.common + 'alertMailConfigLoader',
    alertMailConfigRequest: Config.BASE_API_MAPPING.common + 'alertMailConfigRequest',
    loadUserRole: Config.BASE_API_MAPPING.common + 'loadUserRole',
    addUserRole: Config.BASE_API_MAPPING.common + 'addUserRole',
    editUserRole: Config.BASE_API_MAPPING.common + 'editUserRole',
    deleteUserRole: Config.BASE_API_MAPPING.common + 'deleteUserRole',
    siteConfigLoad: Config.BASE_API_MAPPING.common + 'siteConfigLoad',
    siteConfigSave: Config.BASE_API_MAPPING.common + 'siteConfigSave',
    updateStation: Config.BASE_API_MAPPING.common + 'updateStation',
    deleteStation: Config.BASE_API_MAPPING.common + 'deleteStation',
    loadFlagsetup: Config.BASE_API_MAPPING.common + 'loadFlagsetup',
    addFlagsetup: Config.BASE_API_MAPPING.common + 'addFlagsetup',
    deleteFlagsetup: Config.BASE_API_MAPPING.common + 'deleteFlagsetup',
    editFlagsetup: Config.BASE_API_MAPPING.common + 'editFlagsetup',
    loadQualifierCodeSetup: Config.BASE_API_MAPPING.common + 'loadQualifierCodeSetup',
    qualifierCodeAdd: Config.BASE_API_MAPPING.common + 'qualifierCodeAdd',
    qualifierCodeDelete: Config.BASE_API_MAPPING.common + 'qualifierCodeDelete',
    qualifierCodeUpdate: Config.BASE_API_MAPPING.common + 'qualifierCodeUpdate',
    loadCommentssetup: Config.BASE_API_MAPPING.common + 'loadCommentssetup',
    addCommentSetup: Config.BASE_API_MAPPING.common + 'addCommentSetup',
    commentSetupUpdate: Config.BASE_API_MAPPING.common + 'commentSetupUpdate',
    commentSetupDelete: Config.BASE_API_MAPPING.common + 'commentSetupDelete',
    loadCategorySetup: Config.BASE_API_MAPPING.common + 'loadCategorySetup',
    addCategorySetup: Config.BASE_API_MAPPING.common + 'addCategorySetup',
    categorySetupUpdate: Config.BASE_API_MAPPING.common + 'categorySetupUpdate',
    categorySetupDelete: Config.BASE_API_MAPPING.common + 'categorySetupDelete',

    // Active Directory
    loadActiveTable: Config.BASE_API_MAPPING.common + 'loadUser',
    searchUser: Config.BASE_API_MAPPING.common + 'searchUser',

    // Advanced Parameter
    advancedSetupLoader: Config.BASE_API_MAPPING.common + 'advancedSetupLoader',
    editadvancedparameter: Config.BASE_API_MAPPING.common + 'editadvancedparameter',
    deleteadvancedparameter: Config.BASE_API_MAPPING.common + 'deleteadvancedparameter',
    addadvancedparameter: Config.BASE_API_MAPPING.common + 'addadvancedparameter',

    // IEDSetup Filter
    iedAssessmentSetup: Config.BASE_API_MAPPING.common + 'iedAssessmentSetup',
    iedassessmentupFetch: Config.BASE_API_MAPPING.common + 'iedassessmentupFetch',
    iedSetupFetch: Config.BASE_API_MAPPING.common + 'iedSetupFetch',
    iedsetup: Config.BASE_API_MAPPING.common + 'iedsetup',

    // parameter setup
    parameterSetupLoader: Config.BASE_API_MAPPING.common + 'parameterSetupLoader',
    orderParameter: Config.BASE_API_MAPPING.common + 'orderParameter',
    deleteParameter: Config.BASE_API_MAPPING.common + 'deleteParameter',
    filterParameter: Config.BASE_API_MAPPING.common + 'filterParameter',
    addParameter: Config.BASE_API_MAPPING.common + 'addParameter',
    editParameter: Config.BASE_API_MAPPING.common + 'parameterSave',

    // collector setup
    addCollector: Config.BASE_API_MAPPING.common + 'addCollector',
    CollectorFilter: Config.BASE_API_MAPPING.common + 'collectorFilter',
    deleteCollector: Config.BASE_API_MAPPING.common + 'deleteCollector',
    editloadCollector: Config.BASE_API_MAPPING.common + 'editloadCollector',
    updateCollector: Config.BASE_API_MAPPING.common + 'updateCollector',

    // printer
    CsvDump: Config.BASE_API_MAPPING.common + 'csv', // Config.BASE_API_MAPPING.common + 'CsvDump',
    PdfDump: Config.BASE_API_MAPPING.common + 'pdf', // Config.BASE_API_MAPPING.common + 'PdfDump',
    ExcelDump: Config.BASE_API_MAPPING.common + 'excel', // Config.BASE_API_MAPPING.common + 'CsvDump',
    WordDump: Config.BASE_API_MAPPING.common + 'word',
    HTMLDump: Config.BASE_API_MAPPING.common + 'html',
    pdfUrl: Config.BASE_API_MAPPING.common + 'pdfFileHandler',
    download: Config.BASE_API_MAPPING.common + 'download',
    siteConfUrl: Config.BASE_API_MAPPING.common + 'siteConfFileHandler',

    // AQI Reports
    AQIDataReport: Config.BASE_API_MAPPING.common + 'AQIDataReport',
    AQILevelReport: Config.BASE_API_MAPPING.common + 'AQILevelReport',
    // AQIMonthlyCalenderView: Config.BASE_API_MAPPING.common + 'AQIMonthlyCalenderView',
    AQIMonthlyCalenderView: Config.BASE_API_MAPPING.common + 'dailyCalendar',
    AQIHourlyCalenderView: Config.BASE_API_MAPPING.common + 'hourlyCalendar',
    // AQIHourlyCalenderView: 'http://192.168.1.31:9867/kl/ui/dailyCalendar',
    standard_listing: Config.BASE_API_MAPPING.common + 'standard_listing',
    standard_view: Config.BASE_API_MAPPING.common + 'standard_view',
    standard_generation: Config.BASE_API_MAPPING.common + 'standard_generation',
    standard_delete: Config.BASE_API_MAPPING.common + 'standard_delete',


    // Complaints complaint
    complaintsManagementFilter: Config.BASE_API_MAPPING.common + 'complaintsManagementFilter',
    complaintManagementTableLoader: Config.BASE_API_MAPPING.common + 'fetchComplainList',
    complaintManagementTabLoader: Config.BASE_API_MAPPING.common + 'fetchComplainStats',
    viewComplaintLoader: Config.BASE_API_MAPPING.common + 'fetchComplainDetail',
    deleteComplaintLoader: Config.BASE_API_MAPPING.common + 'deleteComplaint',
    complaintDownload: Config.BASE_API_MAPPING.common + 'download',
    complaintEntryFields: Config.BASE_API_MAPPING.common + 'inspectionOnload',
    complaintEntryUploadFields: Config.BASE_API_MAPPING.common + 'manualComplaintForm',
    complaintTrackerData: Config.BASE_API_MAPPING.common + 'trackComplaint',
    // complaintEntryDropDownData: Config.BASE_API_MAPPING.common + '',
    submitManualComplaint: Config.BASE_API_MAPPING.common + 'manualComplaintEntry',
    addInspectionComplaints: Config.BASE_API_MAPPING.common + 'addInspection',
    inspectionMultiHeaderTable: Config.BASE_API_MAPPING.common + 'inspectionTable',
    inspectionExcelDownload: Config.BASE_API_MAPPING.common + 'excelReport',

    complaintAssignment: Config.BASE_API_MAPPING.common + 'updateAction',
    complaintStatus: Config.BASE_API_MAPPING.common + 'updateStatus',
    downloadComplaintPDF: Config.BASE_API_MAPPING.common + 'pdfDownload',
    complaintConfigurationTableLoader: Config.BASE_API_MAPPING.common + 'loadSetup',
    categoryDeleteAddAction: Config.BASE_API_MAPPING.common + 'updateSetup',

    requestHeadersStationwiseReport: Config.BASE_API_MAPPING.common + 'formJsonLoader',

    // aermod
    get_all_projects: Config.BASE_API_MAPPING.aermod + 'get_all_projects',
    get_project_generated: Config.BASE_API_MAPPING.aermod + 'get_project_generated',
    fetch_all_sites: Config.BASE_API_MAPPING.aermod + 'fetch_all_sites',
    fetch_site_metadata: Config.BASE_API_MAPPING.aermod + 'fetch_site_metadata',
    fetch_list_of_buildings: Config.BASE_API_MAPPING.aermod + 'fetch_list_of_buildings',
    get_site_terrain_details: Config.BASE_API_MAPPING.aermod + 'get_site_terrain_details',
    reportDownload: Config.BASE_API_MAPPING.aermodDownload + 'reports',
    insert_building_component: Config.BASE_API_MAPPING.aermod + 'insert_building_component',
    save_project_details: Config.BASE_API_MAPPING.aermod + 'save_project_details',
    update_building_component: Config.BASE_API_MAPPING.aermod + 'update_building_component',
    generate_heatmap: Config.BASE_API_MAPPING.aermod + 'generate_heatmap',
    downloadAermodHeatMapReport: Config.BASE_API_MAPPING.aermod + 'get_project_generated',
    get_project_details: Config.BASE_API_MAPPING.aermod + 'get_project_details',

    // AqiDataEntry
    AqiDataEntry: Config.BASE_API_MAPPING.common + 'AqiDataEntry',
    aqiEntryParamsMetaData: Config.BASE_API_MAPPING.common + 'aqiEntryParamsMetaData',
    aaqmsStationForm: Config.BASE_API_MAPPING.common + 'aaqmsStationForm',
    saveAAQMSStationForm: Config.BASE_API_MAPPING.common + 'aaqmsSiteUpdate',
    getAAQMSStationList: Config.BASE_API_MAPPING.common + 'loadSiteList',
    deleteaaqmsStation: Config.BASE_API_MAPPING.common + 'deleteAaqmsSite',
    jsonFetch: Config.BASE_API_MAPPING.common + 'jsonFetch',
  };

  // Keys we need to use accross application. For logics and etc
  public static CONSTANTS = {
    dashBoardType: environment.dashBoardType,
    GENERIC_DASHBOARD: 'aqiDashboardType',
    userRole: 'userRole',
    userName: 'userId',
    // station_list: 'station_list',
    // siteId: 'siteId',
    userId: 'userId',
    AQI_STANDARDS: {
      1: {
        label: 'Good',
        message: 'Air quality is considered satisfactory, and air pollution poses little or no risk.'
      },
      2: {
        label: 'Satisfactory',
        // tslint:disable-next-line:max-line-length
        message: 'Air quality is acceptable; however, for some pollutants there may be a moderate health concern for a very small number of people who are unusually sensitive to air pollution.'
      },
      3: {
        label: 'Moderate',
        // tslint:disable-next-line:max-line-length
        message: 'Unhealthy for Sensitive Groups Although general public is not likely to be affected at this AQI range, people with lung disease, older adults and children are at a greater risk from exposure to ozone, whereas persons with heart and lung disease, older adults and children are at greater risk from the presence of particles in the air.'
      },
      4: {
        label: 'Poor',
        message: 'Everyone may begin to experience health effects; members of sensitive groups may experience more serious health effects.'
      },
      5: {
        label: 'Very Poor',
        message: 'Everyone may experience more serious health effects.'
      },
      6: {
        label: 'Severe',
        message: 'Health warnings of emergency conditions. The entire population is more likely to be affected.'
      },
      mapping: {
        Good: 1,
        Satisfactory: 2,
        Moderate: 3,
        Poor: 4,
        'Very Poor': 5,
        Severe: 6
      }
    },
    reports: {
      pdf: 'PdfDump',
      csv: 'CsvDump',
      excel: 'ExcelDump',
      html: 'HTMLDump',
      word: 'WordDump'
    },
    siteStatus: [
      {
        label: 'Active',
        icon: 'assets/images/markers/value-good.png'
      },
      {
        label: 'Inactive',
        icon: 'assets/images/markers/value-poor.png'
      }
    ],
    AQI_LAYER_TYPES: [
      { label: 'AQI', value: 'AQI' },
      { label: 'BARPRESS', value: 'BARPRESS' },
      { label: 'Wind Direction', value: 'WIND' },
      { label: 'Wind Speed', value: 'SWDR' }
    ],
    staticRequestJson: {
      userName: 'glensAdmin',
      userRole: 'Admin',
      station_list: 'AQ_HC,Qidfa,Mobile_Station,Lafarge_Cems,FM',
      siteId: ['site_101', 'site_102', 'site_103', 'site_104'],
      siteName: null
    },
    DATA_VALIDATION_QC: {
      V: { label: 'Validated Data', value: 'V', code: 'data_quality_v', color: '#804d00' },
      U: { label: 'Correct', value: 'U', code: 'data_quality_u', color: '#669999' },
      S: { label: 'Estimated', value: 'S', code: 'data_quality_s', color: '#525916' },
      R: { label: 'Corrected', value: 'R', code: 'data_quality_r', color: '#009900' },
      D: { label: 'Drift', value: 'D', code: 'data_quality_d', color: '#18468c' },
      F: { label: 'Failure', value: 'F', code: 'data_quality_f', color: '#ffcc99' },
      I: { label: 'Invalidated', value: 'I', code: 'data_quality_i', color: '#c32833' },
      M: { label: 'Maintenance', value: 'M', code: 'data_quality_m', color: '#cc99ff' },
      Z: { label: 'Zero Cal', value: 'Z', code: 'data_quality_z', color: '#ffff99' },
      C: { label: 'Span Cal', value: 'C', code: 'data_quality_c', color: '#ff9900' },
      W: { label: 'Warning', value: 'W', code: 'data_quality_w', color: '#cc9900' },
      Y: { label: 'Anomaly', value: 'Y', code: 'data_quality_y', color: '#544aad' },
      O: { label: 'Out of Range', value: 'O', code: 'data_quality_o', color: '#0066cc' },
      L: { label: 'Delayed', value: 'L', code: 'data_quality_l', color: '#71188c' }
    },
    QUALIFIER_CODES: [
      { label: '1 : Deviation from a CFR', value: '1 : Deviation from a CFR' },
      { label: '2 : Operational Deviation', value: '2 : Operational Deviation' },
      { label: '3 : Field Issue', value: '3 : Field Issue' },
      { label: '4 : Lab Issue', value: '4 : Lab Issue' },
      { label: '5 : Outlier', value: '5 : Outlier' },
      { label: '6 : QAPP Issue', value: '6 : QAPP Issue' },
      { label: '7 : Below Lowest Calibration', value: '7 : Below Lowest Calibration' },
      { label: '9 : Negative Value Detected', value: '9 : Negative Value Detected' },
      { label: 'C : Volcanic Eruptions', value: 'C : Volcanic Eruptions' },
      { label: 'CC : Clean Canister Residue', value: 'CC : Clean Canister Residue' },
      { label: 'D : Sandblasting', value: 'D : Sandblasting' },
      { label: 'E : Forest Fire', value: 'E : Forest Fire' },
      { label: 'EH : Estimated', value: 'EH : Estimated' },
      { label: 'RP : Structural Fire', value: 'RP : Structural Fire' }
    ]
  };

  // Alert messages to show in Application
  public static ALERT_MESSAGES: any = {
  };

  public static DUP_USER = {
    input: {
      name: 'sivaram', key: 'glensDevTeam@123'
    },
    // tslint:disable-next-line:max-line-length
    res: 'eyJkYXRhIjogeyJzdGF0dXMiOiAic3VjY2VzcyIsICJzaXRlSWQiOiAic2l0ZV8xMDEiLCAic2l0ZU5hbWUiOiAiQVEgSEMsRnVqYWlyYWgiLCAidXNlclJvbGUiOiAiQWRtaW4iLCAiZmFpbHVyZUNvdW50IjogIjAiLCAibWNlcnQiOiAiZmFsc2UiLCAic3RhdGlvbl9saXN0IjogIkFRX0hDLFFpZGZhLE1vYmlsZV9TdGF0aW9uLExhZmFyZ2VfQ2VtcyxGTSwyMSw3NiIsICJkYXNoYm9hcmRUeXBlIjogIm1hcFZpZXciLCAidXNlcklkIjogImdsZW5zQWRtaW4iLCAicmVmcmVzaFRpbWUiOiA2MDAwMCwgIm1lc3NhZ2UiOiAiIiwgImxhc3RMb2dpbiI6ICIyNy0wMS0yMDIwIDE5OjE4OjAxIn0sICJzdGF0dXMiOiB0cnVlLCAibWVzc2FnZSI6ICJMb2dpbiBTdWNjZXNzZnVsIn0='
  };

  public static USERACCESS: any = {
    admin: ['admin-view', 'gallery', 'video-call', 'user-management'],
    industryUser: ['industry-view', 'gallery', 'video-call'],
    regOfficer: ['reg-jobs', 'gallery', 'video-call'],
    verOfficer: ['ver-jobs', 'gallery', 'video-call']
  };
  public static logger(data) {
    try {
      console.log(data);
    } catch (error) {
      console.log(error);
    }
  }
}
