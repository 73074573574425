import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-colors-table',
  templateUrl: './colors-table.component.html',
  styleUrls: ['./colors-table.component.scss']
})
export class ColorsTableComponent implements OnInit {
  @Input() tableData;
  @Input() options: any = {};
  constructor() { }
  ngOnInit() {
    // Config.logger(this.tableData);
  }

}
