import { Component, OnInit, Input, Output, EventEmitter, ElementRef, ViewChild } from '@angular/core';
import { Config } from 'src/app/config/config';

@Component({
  selector: 'app-bs64-upload',
  templateUrl: './bs64-upload.component.html',
  styleUrls: ['./bs64-upload.component.scss']
})
export class Bs64UploadComponent implements OnInit {
  @Input() imgObject;
  @Input() key;
  @Input() accept;
  @Input() title;
  @Input() multiple;
  @Output() bs64Emitter: EventEmitter<any>;

  public imageObj: any = {};
  constructor() {
    this.bs64Emitter = new EventEmitter<any>();
  }

  ngOnInit() {
    // Config.logger(this.key);
  }

  handleInputChange(e) {
    this.imgObject[this.key] = [];
    if (this.multiple) {
      let fileList: FileList = e.target.files;
      // fileList.forEach(element => {
      for (let i = 0; i < fileList.length; i++) {
        const type = '.' + fileList[i].type.split('/')[1];
        if (!this.accept || this.accept.indexOf(type) > -1) {
          const that = this;
          // const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
          const file = fileList[i];
          this.imageObj.filename = file.name;
          const reader = new FileReader();
          reader.onload = that._handleReaderLoaded.bind(this);
          reader.readAsDataURL(file);
        } else {
          this.bs64Emitter.emit('invalidFormat');
        }
      };
    } else {
      const fileDetails = e.target.files[0];
      const type = '.' + fileDetails.type.split('/')[1];
      if (!this.accept || this.accept.indexOf(type) > -1) {
        const that = this;
        const file = e.dataTransfer ? e.dataTransfer.files[0] : e.target.files[0];
        this.imageObj.filename = file.name;
        const reader = new FileReader();
        reader.onload = that._handleReaderLoaded.bind(this);
        reader.readAsDataURL(file);
      } else {
        this.bs64Emitter.emit('invalidFormat');
      }
    }
  }
  _handleReaderLoaded(e) {
    const reader = e.target;
    if (this.multiple) {
      // this.imgObject[this.key].push(reader.result.split(',')[1]);
      this.imgObject[this.key].push(reader.result);
    } else {
      this.imgObject[this.key] = reader.result.split(',')[1];
    }
    this.bs64Emitter.emit('success');
  }
  buttonAction() {
    try {
      const fileInput: any = document.getElementById('bs64Tag_' + this.key);
      fileInput.value = '';
      fileInput.click();
    } catch (error) {
      Config.logger(error);
    }
  }
}
