import { Component, OnInit } from '@angular/core';
import { CommonServiceLayerService } from 'src/app/services/common-service-layer.service';
import { Config } from 'src/app/config/config';
import { Router } from '@angular/router';
import { DataTableConfig } from 'src/app/shared/table/table-interface';
import { SharedService } from 'src/app/shared/shared.service';

@Component({
  selector: 'app-track-complaints',
  templateUrl: './track-complaints.component.html',
  styleUrls: ['./track-complaints.component.scss']
})
export class TrackComplaintsComponent implements OnInit {
  public loginLogo = { logo: '', title: '' };
  public state; 
  public userMail = '';
  tableConfigData: DataTableConfig;
  constructor(private http: CommonServiceLayerService, private router: Router, private sharedService: SharedService) { }

  ngOnInit() {
    if (['jammukashmir-ccr.glensserver.com'].includes(window.location.hostname)) {
      this.state = "J&K";
    }
    if (['puducherry-ccr.glensserver.com', 'localhost'].includes(window.location.hostname)) {
      this.state = "Puducherry";
    }
    // this.getStateLogo();
    this.setTableConfig();
  }
  getStateLogo() {
    try {
      this.http.others.getStateMetaData().subscribe(
        response => {
          this.loginLogo = response[window.location.hostname] ? response[window.location.hostname] : response['default'];
          return this.loginLogo;
        });
    } catch (error) {
      Config.logger(error);
    }
  }
  setTableConfig() {
    try {
      this.tableConfigData = {
        actions: [
          // { type: 'view', icon: 'fa fa-eye', title: 'View' },
          // {
          //   type: 'delete', icon: 'fa fa-trash', title: 'Delete', class: 'btn-danger'
          // }
        ],
        dtOptions: {
          maxRows: 10,
          searchButton: false,
          columnSearch: false,
          displayOptions: false,
          headerLabel: 'name',
          valueBasedCondition: {
            slaBasedResponseComparison: { green: 'bg-green Comparison', orange: 'bg-orange Comparison', red: 'bg-red Comparison' },
          },
        }
      };
    } catch (error) {
      Config.logger(error);
    }
  }
  getComplaintDetails() {
    try {
      const payLoad = {
        mail: this.userMail,
      };
      this.http.ComplaintsModule.complaintTrackerData(payLoad).subscribe(
        response => {
          if (response && response.status) {
            this.tableConfigData.tableData = response.tableData;
            // this.getDropDownData();
            // this.commentPayLoad = response.formData;
          } else {
            this.sharedService.serviceErrorMessage(response.message);
          }
        });
    } catch (error) {
      console.log(error);
    }
  }
  validateMail(data){
    // const emailPattern = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
    const emailPattern = /^([\w\-\.]+)@((\[([0-9]{1,3}\.){3}[0-9]{1,3}\])|(([\w\-]+\.)+)([a-zA-Z]{2,4}))$/;
    return !data.match(emailPattern);
    // console.log(e);
  }
  goToLogin() {
    this.router.navigate(['/login']);
  }
}
